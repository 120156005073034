import { defineStore } from 'pinia'
import PopUpComponent from '../components/PopUps/PopUp.vue'
import { Dialog } from 'quasar'
import QDialog from 'quasar'

type DialogPosition = 'standard' | 'top' | 'right' | 'bottom' | 'left' | undefined

export const PopUpStore = defineStore('PopUp', {
  state: () => ({
    popUpRef: undefined as typeof QDialog | undefined,
    createPopUp: false,
    phoneSuggestions: false,
    phoneSuggestionsBlur: false,
    objSearchSugg: false,
    awardInfoPopUp: false,
    anySugg: false,
    anySuggBlur: false,
    currentPopUp: '',
    currentPopUpData: null as any,
    PopUpPersistent: false,
    position: 'standart' as DialogPosition,
    maximized: false,
    MobilcreatePopUp: false,
    MobilcurrentPopUp: '',
    MobilcurrentPopUpData: null as any,
    MobilPopUpPersistent: false,
    Mobilposition: 'standart' as DialogPosition,
  }),
  actions: {
    callPopUp(value: string, data?: any, persistent?: boolean, position?: DialogPosition) {
      return new Promise<boolean>((resolve) => {
        Dialog.create({
          component: PopUpComponent,
          persistent: persistent,
          position: position,
          maximized: this.maximized,
          ok: {
            push: true,
          },
          cancel: {
            push: true,
          },
        })
          .onOk(() => {
            resolve(true)
          })
          .onCancel(() => {
            resolve(false)
          })
          .onDismiss(() => {
            resolve(false)
          })
        this.currentPopUp = value
        this.currentPopUpData = data
        this.createPopUp = true
        // this.PopUpPersistent = persistent ? persistent : false
        // this.position = position
      })
    },
    callMobilPopUp(value: string, data?: any, persistent?: boolean, position?: DialogPosition) {
      return new Promise<boolean>((resolve) => {
        Dialog.create({
          component: PopUpComponent,
          persistent: persistent,
          position: position,
          maximized: this.maximized,
          ok: {
            push: true,
          },
          cancel: {
            push: true,
          },
        })
          .onOk(() => {
            resolve(true)
          })
          .onCancel(() => {
            resolve(false)
          })
          .onDismiss(() => {
            resolve(false)
          })
        this.MobilcurrentPopUp = value
        this.MobilcurrentPopUpData = data
        this.MobilcreatePopUp = true
        // this.MobilPopUpPersistent = persistent ? persistent : false
        // this.Mobilposition = position
      })
    },
  },
})
