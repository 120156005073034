<template>
  <q-dialog ref="dialogRef">
    <component :is="createPopUp.$state.currentPopUp" @hide_popup="closePopUp"></component>
  </q-dialog>
</template>

<script setup lang="ts">
import { PopUpStore } from '~/store/popUp'
import { useDialogPluginComponent } from 'quasar'
const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const createPopUp = PopUpStore()

const closePopUp = (state: 'ok' | 'ca') => {
  if (state === 'ok') {
    onDialogOK()
  } else if (state === 'ca') {
    onDialogCancel()
  } else {
    onDialogHide()
  }
}

onMounted(()=>{
createPopUp.$state.popUpRef =dialogRef 
})
</script>

<style lang="scss"></style>
